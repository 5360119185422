import React, { useState, useEffect } from "react";
import Layout from "../../../components/chat-channel/Layout";
import { useMedia } from "use-media";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const image1 = require("../../../assets/images/blog_6/b6_1.webp");
const image2 = require("../../../assets/images/blog_6/b6_2.png");
const image3 = require("../../../assets/images/blog_6/b6_3.jpg");
const image4 = require("../../../assets/images/blog_6/b6_4.jpg");
const image5 = require("../../../assets/images/blog_6/b6_5.jpg");
const image6 = require("../../../assets/images/blog_6/b6_6.jpg");
const image7 = require("../../../assets/images/blog_6/b6_7.jpg");

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Build chatbot for Azure AD and automate repetitive IT tasks | Workativ Blog"
        description="Learn how Azure AD customers are automating repetitive access management tasks using workativ assistant chatbot and streamline various employees IT requests."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={image1}
      />
      <section className="market_main_wrapper chatbot-guide-blog-main-container">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Intelligent IT Process Automation on Unlock Account,
                            Password Reset, and Access Management via Microsoft
                            Azure Active Directory Workflow Automation
                          </h1>
                          <img
                            src={image1}
                            className="mb-4"
                            alt="Intelligent IT Process Automation on Unlock Account"
                          ></img>
                        </div>
                      </div>
                      <div className=" market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://fowmedia.com/stats-linking-employee-experience-to-customer-experience/#:~:text=Research%20shows%20that%20companies%20that,record%20of%20poor%20customer%20experience.&text=It's%20also%20clear%20that%20companies,than%20those%20who%20don't.">
                            Research{" "}
                          </a>
                          shows that companies that excel at customer experience
                          have 1.5 times more engaged employees than companies
                          with a record of poor customer experience. It’s also
                          clear that companies who invest in employee experience
                          can see a higher ROI than those who don’t. So what
                          should you exactly do to keep your employees happy?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          You can start by automating some of your IT processes
                          so that employees can self-serve themselves. Because
                          we humans take pride and satisfaction in solving a
                          problem ourselves instead of depending on another
                          person. So, the big question now is, where should you
                          start?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          You can start by getting rid of the age-old model of
                          static self-service portals and replacing them with
                          AI-powered conversational IT self-service via
                          contextual chatbots. How you ask? Why, with Workativ
                          Assistant of course!
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant is a no-code platform for building
                          contextual chatbots with automated workflows for
                          business apps. You can easily create and add Workativ
                          Assistant’s chatbots to the chat channel of your
                          choice like{" "}
                          <a href="https://workativ.com/conversational-ai-platform/slack-chatbot-guide">
                            Slack{" "}
                          </a>
                          or{" "}
                          <a href="https://workativ.com/conversational-ai-platform/microsoft-teams-vs-slack">
                            Microsoft Teams.
                          </a>{" "}
                          Once you add a Workativ Assistant chatbot to your
                          Slack/Microsoft Teams workspace, your employees can
                          make use of the chatbot to self-serve their IT issues,
                          on-the-go, 24×7, on their laptop or mobile device.
                        </p>
                        <img
                          loading="lazy"
                          src={image2}
                          className="mb-5"
                          alt="Workativ Assistant chatbot to your
                          Slack/Microsoft Teams workspace"
                        ></img>
                        <p class="font-section-normal-text line-height-2">
                          If you have a multi-tenant, cloud-based Identity as a
                          Service (IDaaS) solution like Microsoft Azure Active
                          Directory (AD) as part of your workplace IT support,
                          by connecting it with Workativ Assistant, you can
                          automate the following Azure AD tasks:
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            <a
                              href="https://workativ.com/use-cases/reset-password-automation"
                              className="font-section-normal-text-medium"
                            >
                              {" "}
                              Reset password
                            </a>
                          </span>
                          — Give your employees the ability to change or reset
                          their password, with no administrator or help desk
                          involvement. If an employee forgets their password,
                          they can follow prompts to reset it themselves and get
                          back to work. This ability reduces help desk calls and
                          loss of productivity when a user can’t sign in to
                          their device or an application.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Suspend user
                          </span>
                          — If your help desk team finds any security
                          compromising activities on an employees’ network, they
                          can suspend it immediately before clarifying it with
                          the employee via Workativ Assistant’s Azure AD
                          chatbot.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Unsuspend user
                          </span>
                          — To provide more flexibility to your employees,
                          enable them to unlock their accounts themselves via a
                          Workativ Assistant chatbot on Slack or Microsoft Teams
                          without having to contact help desk.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Add user
                          </span>
                          With Workativ Assistant’s Azure AD chatbot, help desk
                          agents can use the “Add user” automated workflow on
                          the chatbot to create a company email ID for a new
                          employee which gets automatically shared with the
                          employee via text message through the chatbot’s Vonage
                          integration.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Update user
                          </span>
                          Change is something you can compete with. It applies
                          to your employees as well. Be it an employee wanting
                          to change their permanent address or their marital
                          status or their phone number, let them do so
                          themselves without contacting your help desk via
                          Workativ Assistant’s Azure AD chatbot.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Retrieve details about a user
                          </span>
                          Empower your help desk agents by allowing them to
                          lookup an employee’s details without leaving their
                          Slack or Microsoft Teams app via Workativ Assistant’s
                          Azure AD chatbot.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          Sounds interesting? Wait till you see how you can pair
                          Workativ Assistant not just with Azure AD, but with
                          your other existing business apps as well.
                        </p>
                      </div>
                      <div>
                        <h2 class="font-section-sub-header-small">
                          Smarter workflows for Azure AD and existing business
                          apps
                        </h2>
                        <p class="font-section-normal-text line-height-2">
                          By integrating Azure AD and your existing business
                          applications with Workativ Assistant, you ensure a{" "}
                          <a href="https://workativ.com/conversational-ai-platform/ai-self-service-chatbot">
                            seamless self-service experience{" "}
                          </a>
                          for your employees as well as help desk agents. Let’s
                          take a look at how you can set up workflows for some
                          of the popular business apps on the market.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          1. Azure AD integration with Office 365
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Any help desk agent who has used Office 365 knows that
                          just creating a user involves a lot of manual work, be
                          it entering their details field after field or
                          communicating the same to the user via an email or
                          phone call. After that, the help desk agent will have
                          to add the user to the company’s email list on Office
                          365. Whew, sounds like a lot of work, right?
                        </p>
                        <ExistingBlogCta
                          ContentCta="Automate IT Support with Microsoft Azure Active Directory."
                          ButtonText="Book a Demo"
                          isColor="white"
                          backgroundImage={cta_2}
                          mobileBackgroundImage={cta_2_mob}
                        />
                        <p class="font-section-normal-text line-height-2">
                          By{" "}
                          <a href="https://workativ.com/workflow-automation-platform/integrations/microsoft-azure-ad+office-365">
                            connecting your Azure AD and Office 365
                          </a>{" "}
                          accounts with Workativ Assistant, you can set up a
                          chatbot automation such that, after getting details
                          about a user from Azure AD and passing the details to
                          Office 365 for creating an email ID for the user and
                          then adding them to a company email list. Just like a
                          breeze.
                        </p>
                        <img
                          loading="lazy"
                          src={image3}
                          className="mb-5"
                          alt="Azure AD integration with Office 365"
                        ></img>

                        <h5 class="font-section-sub-header-small">
                          2. Jira and Azure AD integration
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          With Workativ Assistant’s{" "}
                          <a href="https://workativ.com/workflow-automation-platform/integrations/jira+microsoft-azure-ad">
                            Jira and Azure AD integration,{" "}
                          </a>
                          you can set up an automation for a Workativ Assistant
                          chatbot such that, after retrieving the details about
                          the employee from Azure AD, the details get passed to
                          the Jira action for adding a user to your company’s
                          Jira workspace and the user gets added automatically
                          as a result. Saves valuable time for your employees as
                          well as your help desk agents.
                        </p>
                        <img
                          loading="lazy"
                          src={image4}
                          className="mb-5"
                          alt="Jira and Azure AD integration"
                        ></img>
                        <h5 class="font-section-sub-header-small">
                          3. Outlook
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          With Workativ Assistant + Outlook + Azure AD
                          integration, you can set up an automation like, after
                          unlocking a user’s account or resetting their
                          password, the Workativ Assistant chatbot will send an
                          email about the same to the user via Outlook so that
                          they know their account has been unlocked/their
                          password reset.
                        </p>
                        <img
                          loading="lazy"
                          src={image5}
                          className="mb-5"
                          alt="Outlook"
                        ></img>

                        <h5 class="font-section-sub-header-small">
                          How you can effortlessly create an Azure AD chatbot
                          with Workativ Assistant
                        </h5>

                        <p class="font-section-normal-text line-height-2">
                          With Workativ Assistant, you can create a chatbot for
                          IT process automation in just a few minutes. Let’s see
                          how.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          1. Workflow builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Workativ assistant comes with easy-to-use no-code
                          workflow builder to help you build simple to complex
                          app-based automations for chatbot in minutes.
                          Integrate chatbot with apps, use pre-built workflows
                          from marketplace, approve requests, connect chatbot
                          with on-prem apps and much more.
                        </p>

                        <img
                          loading="lazy"
                          src={image6}
                          className="mb-5"
                          alt="Workflow builder"
                        ></img>
                        <h5 class="font-section-sub-header-small">
                          2. Chatbot Builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Our easy-to-use no-code chatbot builder helps you
                          build and manage simple to complex conversations,
                          FAQs, integrate app workflows, and personalize your
                          bot in minutes. Deliver autonomous workplace support
                          with purpose built intelligent chatbots.
                        </p>
                        <img
                          loading="lazy"
                          src={image7}
                          className="mb-5"
                          alt="Chatbot Builder"
                        ></img>
                      </div>

                      <NocodeWrapper />
                      <h6 className="font-section-sub-header-small-bold">
                        Azure AD + Workativ Assistant = Savings!
                      </h6>

                      <div className="market_wrapper_page ">
                        <h6 className="font-section-sub-header-small ">
                          1. No-code platform to create chatbots that perform
                          Azure AD tasks in just minutes
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          No need to get a developer on board to create a
                          chatbot that takes care of Azure AD tasks using
                          Workativ Assistant. Workativ Assistant’s Automation
                          Designer enables you to{" "}
                          <a href="https://workativ.com/conversational-ai-platform">
                            create a chatbot in just three simple steps
                          </a>{" "}
                          with just a few clicks. Or you can altogether skip
                          this by going to the Automations Marketplace that
                          houses over 200+ ready-to-use process automations
                          out-of-the-box and downloading the Azure AD automation
                          of your choice. And, you can set up any kind of
                          conversation/dialog flow for a chatbot with Workativ
                          Assistant’s Dialog Designer using the plethora of
                          options the Dialog Designer powers you with.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          2. Creating a Synergy between your existing Business
                          Apps
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Like the Jira Azure AD integration worked
                          synergistically, you can combine your other business
                          apps creating an automated workflow that does much
                          more with those apps in just a single run.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          3. Faster resolution of repetitive IT issues via
                          on-the-go Self-Service
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Since Workativ Assistant’s chatbots will be added to
                          your business’ Slack or Microsoft Teams workspace, you
                          enable your employees to take care of their IT issues
                          themselves from the comfort of their chat hub on their
                          laptop/mobile phone, on-the-go. They don’t have to
                          contact help desk for unlocking their account or
                          resetting their password using Workativ Assistant’s
                          Azure AD self-service for password reset and unlock
                          account.
                        </p>

                        <h5 className="font-section-sub-header-small">
                          4. Better ROI
                        </h5>

                        <p class="font-section-normal-text line-height-2">
                          By making Workativ Assistant’s chatbot a part of your
                          business’ internal IT support, you ensure that you get
                          the{" "}
                          <a href="https://workativ.com/conversational-ai-platform/nocode-chatbot">
                            best ROI on your business’ internal workplace
                            support
                          </a>{" "}
                          by deflecting unnecessary calls/tickets to help desk
                          for password resets and unlock accounts thereby
                          improving overall employee and help desk agent morale.
                        </p>

                        <p class="font-section-normal-text-medium line-height-2">
                          So what are you waiting for? Make your employee
                          support awesome by signing up for a FREE trial at
                          <a href="https://workativ.com/">
                            &nbsp;workativ.com
                          </a>{" "}
                          today!
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service">
                                Conversational AI Chatbot for employee service
                                automation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-tools-to-build-conversational-ai-chatbot">
                                {" "}
                                Conversational AI Tools—Top 6 Tools To Build
                                Conversational AI Chatbot
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/chatbot-best-practices-for-designing-a-conversational-ai">
                                Chatbot Best Practices for designing a
                                Conversational Experience with Workativ
                                Assistant
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper no_code_wrapper_small mt-0 ">
      <h4>No Code - Free Microsoft Azure AD Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="active directory chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
